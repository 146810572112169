export const menuLinks = (menu_data) => {
    //console.log("menu_data", menu_data);

    var menu_link = '';

    if(menu_data){
        if(menu_data.Secondary_URL){
            menu_link = menu_data.Secondary_URL;
        } else{
            if(menu_data?.Parent_Menu !== null && menu_data?.Sub_Menu !== null && menu_data?.Secondary_Sub_Menu !== null){
                menu_link = '/'+menu_data.Parent_Menu.Primary_URL+'/'+menu_data.Sub_Menu.Primary_URL+'/'+menu_data?.Secondary_Sub_Menu?.Primary_URL+'/'+menu_data.Primary_URL+'/';
            }else if(menu_data?.Parent_Menu !== null && menu_data?.Sub_Menu !== null){
                menu_link = '/'+menu_data.Parent_Menu.Primary_URL+'/'+menu_data.Sub_Menu.Primary_URL+'/'+menu_data.Primary_URL+'/';
            } else if(menu_data?.Parent_Menu !== null){
                menu_link = '/'+menu_data.Parent_Menu.Primary_URL+'/'+menu_data.Primary_URL+'/';
            } else{
                menu_link = '/'+menu_data.Primary_URL+'/';
            }
        }
    }
    return menu_link;
}

export const CustomLinks = {
    res_rent_link: "residential/property/to-rent/in-london",
    testimonials: "about-residential-land/customer-testimonials",
    news_media: "about-residential-land/latest-property-news",
    explore_london: "london-area-guides",
    about: "about-residential-land",
    our_team: "about-residential-land/our-team",
    careers: "about-residential-land/careers-at-residential-land",
    terms_conditions: "terms-and-conditions",
    privacy_policy: "privacy-policy",
    login: "login",
    forgot_password: "forgot-password",
    register: "registration",
    dashboard: "dashboard",
    register_thankyou: "register-agent",
    my_profile: "my-profile",
    our_building: "about-residential-land/our-building",
    property_building: "property-building",
}