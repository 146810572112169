import * as React from "react"
import {Link} from "gatsby"

// Styles
import "./FooterSocialLinks.scss"

const FooterSocialLinks = ({footerSocialLinksData}) => {

    return (
        <div className="footer-social-links-wrapper">
            <a href={footerSocialLinksData.Facebook_Link} target={"_blank"}>
                <i className="icon icon-footer-fb"></i>
            </a>
            <a href={footerSocialLinksData.Twitter_Link} target={"_blank"}>
                <i className="icon icon-footer-twitter"></i>
            </a>
            <a href={footerSocialLinksData.Insta_Link} target={"_blank"}>
                <i className="icon icon-footer-insta"></i>
            </a>
        </div>
    )
}

export default FooterSocialLinks
