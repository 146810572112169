import React, { useState, useEffect }  from "react"
import { Link, navigate } from "gatsby";
import { Container, Row, Col } from "react-bootstrap"

import $ from 'jquery';

// Styles
import "../Header/Header.scss"

// Images
import logo from "../../images/resiland-logo.svg"
import Burger_BG_Logo from "../../images/burger_bg_logo.png"

// Components
import { menuLinks } from "../common/menuUtils";
import { isLoggedIn, logout, isBrowser } from "../../services/auth"

import MegaMenuOverlayItem from "./MegaMenuOverlayItem"

const MegaMenuOverlay = ({ menuData, showMenu, setShowMenu, selectedIndex, setSelectedIndex, subMenuId, setSubMenu, isAuth }) => {

    // useEffect(() => {
    //     document.body.style.overflow = showMenu ? 'hidden' : 'auto';
    // }, [showMenu]);

 
    //console.log("subMenuId", subMenuId);

    useEffect(()=>{
        subMenuId && getSubMenu(subMenuId);
    },[subMenuId])

    const getSubMenu = (menu_id) =>{
        $(".mega-menu-list-item .sub_menu").not("#accord_menu_"+menu_id).slideUp('slow');
        $("#accord_menu_"+menu_id).slideToggle('slow');     
    }

    const logOut = () =>{
        logout();
        if(isBrowser){
            navigate(`/login/`);
        }    
    }

    var menu_Object = menuData && Object.keys(menuData).filter(key => menuData[key].Show_Burger_Menu === true);

    return (
        <div className={`mega-menu-overlay-container ${showMenu ? "open-service" : ""}`}>
            <img src={Burger_BG_Logo} className="burger_bg" alt="Resi.Land"/>
            
            <div className="mega-menu-content">
                <div className="mega-menu-header">
                    <span className="white_logo">
                        <img src={logo} alt="logo" />
                    </span>

                    
                    <span
                        className="mega-menu-close-button"
                        onClick={ () => {
                            //document.body.style.overflow = 'unset';
                            setTimeout( () => {
                                setShowMenu( false );
                                setSubMenu('');
                            }, 100);                         
                        }}

                    >
                        <img
                            src={ require(`../../images/close-icon.svg`).default }
                            alt="Close"
                        />
                    </span>
                    <span className="header-account-menu-item">
                    {isLoggedIn() ? 
                        <Link to="/dashboard/">
                            <i className="icon icon-header-agent"></i>
                            <p>
                                AGENT
                            </p>
                        </Link>
                        :
                        <Link to="/login/">
                            <i className="icon icon-header-agent"></i>
                            <p>
                                AGENT
                            </p>
                        </Link>
                    }                    
                    </span>
                </div>
                <div className="mega-menu-list">
                    <Row>
                        <Col
                            lg={{ span: 6, offset: 3 }}
                            md={{ span: 12 }}
                        >
                            <ul className="mega-menu-lvl1-collapsable-list">
                                {
                                   menuData && menuData.length > 0 && menuData.filter(item => item.Show_Burger_Menu === true && item.Label !== "Home" && item.Parent_Menu === null).map( ( item, index ) => {

                                        if(isAuth){
                                            if(item.LoggedInMenu){
                                                return (
                                                    <>
                                                        <MegaMenuOverlayItem item={item} menuData={menuData} setSelectedIndex={setSelectedIndex} index={index} selectedIndex={selectedIndex} menu_Object={menu_Object} subMenuId={subMenuId} getSubMenu={getSubMenu}/>
                                                    </>
                                                )
                                            }
                                        } else{
                                            if(!item.LoggedInMenu || (item.Label === "Rent" || item.Label === "Buy")){
                                                return (
                                                    <MegaMenuOverlayItem item={item} menuData={menuData} setSelectedIndex={setSelectedIndex} index={index} menu_Object={menu_Object} subMenuId={subMenuId} selectedIndex={selectedIndex} getSubMenu={getSubMenu} />
                                                )
                                            }
                                        }
                                    })
                                }

                                {isAuth && 
                                    <li class="mega-menu-list-item">
                                        <div class="mega-menu-list-item-wrapper">
                                            <span class="mega-menu-list-header">
                                                <a href="javascript:;" onClick={logOut}><h4>Log out</h4></a>
                                            </span>
                                        </div>
                                    </li> 
                                }                           
                            </ul>
                        </Col>
                    </Row>
                </div>
            </div>
            {/* <div
                className="mega-menu-overlay-watermark"
            >            
                <Image
                    src={ require(`../../images/residential-land-black-logo.png`).default }
                    alt="Starberry logo"
                    fluid
                />
            </div> */}
        </div>
    )
}

export default MegaMenuOverlay