import React from "react"
import { getSrc } from "gatsby-plugin-image"
import ImageTransform from "../components/common/ggfx-client/module/components/image-transform";
import NoImage from "../images/no-image.png"

var strapi_url = process.env.GATSBY_STRAPI_SRC;

export const ImageModule = (props) => {
    if(props?.ImageSrc && props?.ImageSrc?.url){

        var image_url = props.ImageSrc.url;
        if(props.ImageSrc.url_sharp){
            image_url = getSrc(props.ImageSrc.url_sharp)
        }

        var alt_txt = props.altText ? props.altText : props.ImageSrc.alternativeText;

        return(
            <>
                {
                    props.imagename ? (<>
                        <ImageTransform imagesources={props.ImageSrc.url} renderer={props.renderer} imagename={props.imagename} attr={{className:props.classNames, alt: props.altText ? props.altText : props.ImageSrc.alternativeText }} imagetransformresult={props.imagetransforms} id={props.strapi_id} />
                        </>
                    ) : (
                        <img className={props.classNames} src={image_url} alt={alt_txt} title={props.title} />
                    )
                }
                
            </>
        )
    } else{
        return <img src={NoImage} alt="img" />;
    }
}
