import { useQuery} from '@apollo/client';
import { useMutation } from '@apollo/react-hooks';
import { Queries } from "./common_queries";

export const FeaturedAreaGuides = () => {
    return useQuery(Queries.FeaturedAreaGuides);
}

export const GetSubMenus = (menu_id) => {
    return useQuery(Queries.GetSubMenus,{
        variables: {menu_id: menu_id}
    });
}

export const GetAreaGuides = () => {
    return useQuery(Queries.GetAreaGuides);
}

export const GetAreaGuidesLimit = () => {
    return useQuery(Queries.GetAreaGuidesLimit);
}

export const GetAreaGuideDetails = (slug) => {
    return useQuery(Queries.GetAreaGuideDetails,{
        variables: {slug: slug}
    });
}

export const GetNewsList = () => {
    return useQuery(Queries.GetNewsList);
}

export const GetNewsCategories = () => {
    return useQuery(Queries.GetNewsCategories);
}

export const GetNewsDetails = (slug) => {
    return useQuery(Queries.GetNewsDetails,{
        variables: {slug: slug}
    });
}

export const SimilarNews = (cat, id) => {
    return useQuery(Queries.SimilarNews,{
        variables: {cat: cat, id: id}
    });
}

export const GetTestimonials = () => {
    return useQuery(Queries.GetTestimonials);
}

export const GetAccreditations = () => {
    return useQuery(Queries.GetAccreditations);
}

export const GetTeamCategories = () => {
    return useQuery(Queries.GetTeamCategories);
}

export const GetTeams = () => {
    return useQuery(Queries.GetTeams);
}

export const GetTeamDetails = (slug) => {
    return useQuery(Queries.GetTeamDetails,{
        variables: {slug: slug}
    });
}

export const FeaturedTeams = () => {
    return useQuery(Queries.FeaturedTeams);
}

export const GetCareers = () => {
    return useQuery(Queries.GetCareers);
}

export const CareersDetails = (slug) => {
    return useQuery(Queries.CareersDetails,{
        variables: {slug: slug}
    });
}

export const PopularSearch = (slug) => {
    return useQuery(Queries.PopularSearch,{
        variables: {slug: slug}
    });
}

export const AreaGuidebyName = (name) => {
    return useQuery(Queries.AreaGuidebyName,{
        variables: {name: name}
    });
}