import React, {useEffect} from "react"
import {Link} from "gatsby"
import { Container, Row, Col } from "react-bootstrap"


// Styles
import "../Header/Header.scss"

// Images
import logo from "../../images/resiland-logo.svg"

// Components
import Menu from "../Menu/index"
import MegaMenuOverlay from "./MegaMenuOverlay"
import { useState } from "react"

import { isLoggedIn} from "../../services/auth"
import useWindowSize from "../common/useWindowSize";

const Header = ( { headerData, variant, menu_Data } ) => {
  const { width } = useWindowSize();
  const [scroll, setScroll] = useState(false);
  const [subMenuId, setSubMenuId] = useState('');
  const [isAuth, setIsAuth] = useState(isLoggedIn())

  const [ selectedIndex, setSelectedIndex ] = useState("");
  const [ showMenu, setShowMenu ] = useState( false );

  useEffect(()=>{
    setTimeout(()=>{
      window.scrollTo(0,0);
    }, 200) 
  },[])

  useEffect(() => {
    document.body.style.overflow = showMenu ? 'hidden' : 'auto';
  }, [showMenu]);

  useEffect(() => {
    try {
      window.addEventListener("scroll", () => {
        if(width < 1200){          
          setScroll(window.scrollY > 40);
        } else if(width > 1200 && width < 1400){
          setScroll(window.scrollY > 70);
        } else{
          setScroll(window.scrollY > 120);
        }
      });
    } catch {}
  }, [width]);

  return (
    
    <header>
      <Container fluid className={ `header ${ scroll ? "regular fixed" : variant || 'regular' }` }>
        <Row className="header-content">
          <Col lg={ 10 } md={ 8 } xs={ 7 }>
            <div className="header-content-left">
              <Link to="/" className="logo-wrapper">
                <img src={ logo } alt="logo" />
              </Link>
              <Menu
                menuData={menu_Data}
                showMenu={ showMenu }
                setShowMenu={ setShowMenu }
                selectedIndex={ selectedIndex }
                setSelectedIndex={ setSelectedIndex }
                setSubMenu={setSubMenuId}
                isAuth={isAuth}
              />
            </div>
          </Col>
          <Col className="header-account-menu">
            <span className="header-account-menu-item">
              {isLoggedIn() ? 
                <Link to="/dashboard/">
                  <i className="icon icon-header-agent"></i>
                  <p>
                    AGENT
                  </p>
                </Link>
                :
                <Link to="/login/">
                  <i className="icon icon-header-agent"></i>
                  <p>
                    AGENT
                  </p>
                </Link>
              }
              
            </span>
            <span
              className="header-account-menu-item"
              onClick={ () => {
                setSelectedIndex( null )
                setShowMenu( !showMenu )
              } }
            >
              <img
                src={ require( `../../images/hamburger-menu-icon.png` ).default }
                alt="Menu"
              />
              <p>
                MENU
              </p>
            </span>
          </Col>
        </Row>
      </Container>
      {
        ( showMenu ) && (
          <MegaMenuOverlay
            menuData={menu_Data}
            showMenu={ showMenu }
            setShowMenu={ setShowMenu }
            selectedIndex={ selectedIndex }
            setSelectedIndex={ setSelectedIndex }
            subMenuId={subMenuId}
            setSubMenu={setSubMenuId}
            isAuth={isAuth}
          />
        )
      }
    </header>
  )
}

export default Header
