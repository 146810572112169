import React, { useState, useEffect }  from "react"
import { Link } from "gatsby";

import $ from 'jquery';

// Components
import { menuLinks } from "../common/menuUtils";

const MegaMenuOverlayItem = ({ item, menuData, setSelectedIndex, selectedIndex, index, menu_Object, subMenuId, getSubMenu }) => {

    // useEffect(()=>{
    //     subMenuId && getSubMenu(subMenuId);
    // },[subMenuId])

    // const getSubMenu = (menu_id) =>{
    //     $(".mega-menu-list-item .sub_menu").not("#accord_menu_"+menu_id).slideUp('slow');
    //     $("#accord_menu_"+menu_id).slideToggle('slow');     
    // }

    const isSelectedMenu = ( selectedIndex === index );
    const toggleIcon = isSelectedMenu ? 'minus-icon.svg' : 'plus-icon.svg';

    var SubMenu = menu_Object && menu_Object.filter(key => menuData[key].Parent_Menu && menuData[key].Parent_Menu.id === item.id && menuData[key].Sub_Menu === null);

    //console.log("isSelectedMenu", isSelectedMenu, selectedIndex, index, subMenuId);


    return (
        <li className="mega-menu-list-item">
            <div className="mega-menu-list-item-wrapper">
                <span className="mega-menu-list-header">
                    
                    {/* <Link to={menuLinks(item)}>
                        <h4>{ item.Label }</h4>
                    </Link> */}
                    {item.Link_Type === "Nolink" ?
                        <a href={"javascript:;"}>
                            <h4>{ item.Label }</h4>
                        </a>
                    :
                        <Link to={menuLinks(item)}>
                            <h4>{ item.Label }</h4>
                        </Link>
                    }
                    
                    
                    {SubMenu.length > 0 && 
                    <span
                        className="full-width-plus-icon"
                        onClick={ () => {isSelectedMenu ? setSelectedIndex( null ) : setSelectedIndex( index ); getSubMenu(item.id)} }
                    >
                        <img
                            src={ require(`../../images/${ toggleIcon }`).default }
                            alt="Close"                 
                        />
                    </span>
                    }
                </span>
                { ( SubMenu && SubMenu.length > 0 ) && (
                    <div className={`sub_menu`} id={`accord_menu_${item.id}`}>
                        <ul className="mega-menu-lvl2-collapsable-list">
                            {
                                SubMenu.map((key2) => {
                                    return (
                                        <RenderSubMenu menuData={menuData} item={ menuData[key2] } SecondarySubMenu ={menu_Object && menu_Object.filter(key3 => menuData[key3].Secondary_Sub_Menu === null && menuData[key3].Sub_Menu && menuData[key3].Sub_Menu.id === menuData[key2].id)}
                                        />
                                    )
                                })
                            }
                        </ul>
                    </div>
                ) }
            </div>
        </li>
    )
}

export default MegaMenuOverlayItem

const RenderSubMenu = ( {item, SecondarySubMenu, menuData} ) => {
    const [ openMenu, setOpenMenu ] = useState( false );
    const toggleIcon = openMenu ? 'minus-icon.svg' : 'plus-icon.svg';

    const getSubMenu2 = (menu_id) =>{
        $(".mega-menu-list-item .sec_sub_menu").not("#accord_submenu_"+menu_id).slideUp('slow');
        $("#accord_submenu_"+menu_id).slideToggle('slow');
        $("#submenu_icon_"+menu_id).toggleClass("icon-plus");
        $("#submenu_icon_"+menu_id).toggleClass("icon-minus");
    }
    //console.log("SecondarySubMenu",  SecondarySubMenu)
    return (
            <li>
                <div className="mega-menu-list-item-wrapper">
                    <span className="mega-menu-list-header">
                        <Link to={menuLinks(item)}>
                            <h6>{ item.Label }</h6>
                        </Link>
                        {SecondarySubMenu && SecondarySubMenu.length > 0 && 
                            <span
                            className="full-width-plus-icon"
                            onClick={ () => {setOpenMenu( !openMenu ); getSubMenu2(item.id)} }
                            >
                                <img
                                src={ require(`../../images/${ toggleIcon }`).default }
                                alt="Close"
                            />
                            </span>
                        }
                    </span>

                    {
                        ( SecondarySubMenu && SecondarySubMenu.length > 0 ) && (
                        <div className={`sec_sub_menu`} id={`accord_submenu_${item.id}`}>
                            <ul className={`mega-menu-lvl3-collapsable-list`}>
                                {
                                    SecondarySubMenu.map(( key3 ) => {
                                        var item = menuData[key3];
                                        return(
                                            <li>
                                                <div className="mega-menu-list-item-wrapper">
                                                    <span className="mega-menu-list-header">
                                                        <Link to={menuLinks(item)}>
                                                            { item.Label }
                                                        </Link>
                                                    </span>
                                                </div>                                                        
                                            </li>
                                        )
                                    })
                                }                                
                            </ul>
                        </div>
                        )
                    }
                    
                </div>                                                        
            </li>

    )
}