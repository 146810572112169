import * as React from "react"
import {ImageModule} from '../../../../modules/Image_Module'

// Styles
import "./FooterCompanies.scss"

const FooterCompanies = ( {footerCompaniesData} ) => {

    //console.log("footerCompaniesData", footerCompaniesData);

    return (
        <div className="footer-companies">
            {
                footerCompaniesData && footerCompaniesData.map( ( footerCompaniesItem, footerCompaniesIndex ) => {
                    return (
                        <a
                            key={ footerCompaniesIndex }
                            href={ footerCompaniesItem.Logo_Link }
                            target="_blank"
                        >
                            <ImageModule ImageSrc={footerCompaniesItem.Image} />
                        </a>
                    )
                } )
            }
        </div>
    )
}

export default FooterCompanies
