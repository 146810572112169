import * as React from "react"
import { Link } from "gatsby";

import { menuLinks } from "../common/menuUtils";

const MenuItem = ( { menu_item, setShowMenu, setSelectedIndex, setSubMenu, index} ) => {
	return (
		<>
            {
                menu_item.Link_Type === "Nolink" ? 
                <li
                    key={ index }
                    onClick={ () => {
                        setSelectedIndex( index )
                        setShowMenu( true )
                        setSubMenu(menu_item.id)
                    } }
                >
                    <a href={"javascript:;"}>{menu_item.Label}</a>
                </li>
            :
                <li>
                    <Link to={menuLinks(menu_item)}>{menu_item.Label}</Link>
                </li>
            }
        </>
	)
}

export default MenuItem
