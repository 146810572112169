import * as React from "react"
import { Nav } from "react-bootstrap"
import { Link } from "gatsby";

import { menuLinks } from "../common/menuUtils";
import MenuItem from "./MenuItem";

// Styles
import "../Menu/Menu.scss"

const Menu = ( { menuData, showMenu, setShowMenu, selectedIndex, setSelectedIndex, setSubMenu, isAuth } ) => {

	const[isAuthState, setIsAuth] = React.useState(false)

	React.useEffect(()=>{
		setIsAuth(isAuth)
	}, [isAuth])


	return (
		isAuthState ? (
			<Nav className={`navigation-menu loggedin_menu`}>

				{ menuData && Object.keys(menuData).filter(key => menuData[key].Show_Header_Menu === true).length > 0 && (
					<ul>
						{ Object.keys(menuData).filter(key => menuData[key].Show_Header_Menu === true).map((key, index) => {
							if(menuData[key].LoggedInMenu){
								return (

									<MenuItem setSelectedIndex={setSelectedIndex} setShowMenu={setShowMenu} setSubMenu={setSubMenu} menu_item = {menuData[key]} index={index}/>
		
								)
							}							
						} ) }
					</ul>
				) }
			</Nav> 
		)
		:	
		(
			<Nav className={`navigation-menu`}>
				{ menuData && Object.keys(menuData).filter(key => menuData[key].Show_Header_Menu === true).length > 0 && (
					<ul>
						{ Object.keys(menuData).filter(key => menuData[key].Show_Header_Menu === true).map((key, index) => {
							return (
								<MenuItem setSelectedIndex={setSelectedIndex} setShowMenu={setShowMenu} setSubMenu={setSubMenu} menu_item = {menuData[key]}  index={index}/>
							)
							
						} ) }
					</ul>
				) }
			</Nav>
		)


	)
}

export default Menu
