import React, { useState } from "react"
import {Link} from "gatsby"
import { Col, Row } from "react-bootstrap"
import { menuLinks } from "../../../common/menuUtils";

// Styles
import "./FooterMenuListMobile.scss"

const FooterMenuListMobile = ( { footerMenuListData = [] } ) => {

    const [ selectedMenuIndex, setSelectedMenuIndex ] = useState( null );

    return (
        <Row className="footer-menu-list-mobile-wrapper">
            {
                footerMenuListData && footerMenuListData.map( ( mainMenuItem, mainMenuIndex ) => {
                    const isSelectedmenu = selectedMenuIndex === mainMenuIndex;
                    const iconSource = isSelectedmenu ? 'minus-icon.png' : 'plus-icon.png';
                    const isLastItem = footerMenuListData.length - 1 === mainMenuIndex;
                    return (
                        <Col
                            className={ `footer-menu-list ${ isLastItem ? 'mb-0' : '' }` }
                            key={ mainMenuIndex }
                            xs={ 12 }
                        >
                            <span className="footer-menu-list-header">
                                <p className="footer-menu-list-name">{mainMenuItem.Heading}</p>
                                <span
                                    onClick={ () => isSelectedmenu ? setSelectedMenuIndex( null ) : setSelectedMenuIndex( mainMenuIndex ) }
                                >
                                    <img
                                        src={ require( `../../../../images/${ iconSource }` ).default }
                                        width="auto"
                                        height="auto"
                                        alt="Plus Icon"
                                    />
                                </span>
                            </span>
                            { ( isSelectedmenu ) && (
                                <ul className="footer-menu-list-body">
                                    {
                                        mainMenuItem.Menu_Module.map( ( subMenuListItem, subMenuIndex ) => {
                                            return (
                                                <li key={ subMenuIndex }>
                                                    <Link to={ subMenuListItem.Custom_Link ? subMenuListItem.Custom_Link : menuLinks(subMenuListItem.all_menu) }>{ subMenuListItem.Label }</Link>
                                                </li>
                                            )
                                        } )
                                    }
                                </ul>
                            ) }
                        </Col>
                    )
                } )
            }
        </Row>
    )
}

export default FooterMenuListMobile
