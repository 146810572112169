import React, { useState } from "react";
import { Col } from "react-bootstrap";
import { Link } from "gatsby";
import HTMLReactParser from 'html-react-parser';
import { useStaticQuery, graphql } from "gatsby"

const PopularSearchItem = (props) => {

    //console.log("props_item", props?.data?.Modules[0].Popular_Search_Module)

    const data = useStaticQuery(graphql`
     query popularSearch {  
       glstrapi{

        globalModule {
            Default_Popular_Search {
                Popular_Search_Module {
                    popular_search {
                        Name
                        Content
                        id
                    }
                }
            }
        }

       }
     }
   `)

   var Popular_Search = data.glstrapi?.globalModule?.Default_Popular_Search; 

    return (

        <div className="footer-nav">
            {props?.data?.Popular_Search_Module?.length > 0 ? (
                    props?.data?.Popular_Search_Module?.map((item, index) =>{     // Static page Popular Search
                        if(index < 4){
                            return(<>
                                {item.popular_search.Content && HTMLReactParser(item.popular_search.Content)}
                            </>
                            )
                        }
                    })
                ) : (
                    Popular_Search.Popular_Search_Module?.map((item, index) =>{    // Default Popular Search
                        if(index < 4){
                            return(<>
                                {item.popular_search.Content && HTMLReactParser(item.popular_search.Content)}
                            </>
                            )
                        }
                    })
                )
            }
        </div>

    );
};
export default PopularSearchItem;
