import gql from "graphql-tag"

/* ========== FeaturedAreaGuides ======== */
const FeaturedAreaGuides = gql`
query FeaturedAreaGuides{
  areaGuides(limit:12, sort:"createdAt:desc"){
    id
    Name
    URL
    createdAt
    Banner_Image{
      url
      alternativeText
    }
    imagetransforms
  }
}`

/* ========== Get Submenus ======== */
const GetSubMenus = gql`
query GetSubMenus($menu_id: String!){
  allMenus(where:{Parent_Menu:{id:$menu_id}}){
    id
    Label
    Page_Name
    Primary_URL
    Secondary_URL
    Parent_Menu{
      Primary_URL
      id
      Label
      Page_Name
    }
    Sub_Menu{
      Primary_URL
    }
    Secondary_Sub_Menu{
      Primary_URL
    }
  }
}`

/* ========== Get AreaGuides ======== */
const GetAreaGuides = gql`
query GetAreaGuides{
  areaGuides(sort:"Sort:ASC"){
    id
    Name
    URL
    Banner_Content
    Banner_Image {
      url
      alternativeText
    }
    imagetransforms
    Modules{
      ...on ComponentComponentFeaturedProperties{
        Show_Featured_Properties
        Property_Module{
          Properties{
            display_address
          }
        }
      }
    }
  }
}`

/* ========== Get AreaGuides ======== */
const GetAreaGuidesLimit = gql`
query GetAreaGuidesLimit{
  areaGuides(sort:"Sort:ASC", limit:3){
    id
    Name
    URL
    Banner_Content
    Banner_Image {
      url
      alternativeText
    }
    imagetransforms
    Modules{
      ...on ComponentComponentFeaturedProperties{
        Show_Featured_Properties
        Property_Module{
          Properties{
            display_address
          }
        }
      }
    }
  }
}`


/* ========== Get AreaGuide Details ======== */
const GetAreaGuideDetails = gql`
query GetAreaGuideDetails($slug: String!){
  areaGuides(where:{URL:$slug}){
    id
    Name
    URL
    Meta_Title
    Meta_Description
    Banner_Title
    Banner_Content
    Banner_CTA_Label
    Banner_CTA_Link
    Content
    Region
    Latitude
    Longitude
    Banner_Image {
      url
      alternativeText
    }
    Slider_Images{
      url
      alternativeText
    }
    imagetransforms

    Modules{
      __typename
      ...on ComponentComponentFeaturedProperties{
        Show_Featured_Properties
        Title
        Content
        View_All_Link
        Property_Module{
          Properties{
            _id
            id
            display_address
            slug
            price
            bedroom
            bathroom
            images
            department
            search_type
            status
            imagetransforms

          }
        }
      }
      
      ...on ComponentComponentAreaGuideComponent{
        id
        Title
        Content
        Section_BG_Color
        Restaurant_List{
          Name
          Content
          Image{
            url
            alternativeText
          }
        }
      }
    }
  }
}`

/* ========== Get News List ======== */
const GetNewsList = gql`
query GetNewsList{
  newsAndInsights(sort:"createdAt:desc"){
    id
    Title
    URL
    Published_Date
    Intro_Text
    Content
    Tags
    news_categories{
      id
      Title
      URL
    }
    Image{
      url
      alternativeText
    }
    imagetransforms
  }
}`

/* ========== Get News Categories ======== */
const GetNewsCategories = gql`
query GetNewsCategories{
  newsCategories{
    id
    Title
    URL
    news_insights{
      id
      Title
      URL
    }
  }
}`

/* ========== News Details ======== */
const GetNewsDetails = gql`
query GetNewsDetails($slug: String!){
  newsAndInsights(where:{URL:$slug}){
    id
    Title
    URL
    Published_Date
    Content
    Author
    Tags
    news_categories{
      id
      Title
      URL
    }
    Image{
      url
      alternativeText
    }
    imagetransforms
    Modules{
      ...on ComponentComponentNewsContentBlock{
        Heading
        Content
        Image{
          url
          alternativeText
        }
      }
    }
    
  }
}`


/* ========== Similar New ======== */
const SimilarNews = gql`
query SimilarNews($cat:String!, $id:ID!){
  newsAndInsights(sort:"createdAt:desc", limit:3, where:{news_categories:{Title_in:$cat}, id_nin:$id}){
    id
    Title
    URL
    Published_Date
    Tags
    Image{
      url
      alternativeText
    }
    imagetransforms
  }
}`

/* ========== Testimonials ======== */
const GetTestimonials = gql`
query GetTestimonials{
  testimonials(sort:"createAt:Desc"){
    id
    Name
    Content
    Location
    Published_Date
    Client_Name
    Image{
      url
      alternativeText
    }
    imagetransforms
  }
}`

/* ========== Accreditations ======== */
const GetAccreditations = gql`
query GetAccreditations{
  accreditations(sort:"createdAt:asc"){
    id
    Name
    Content
    Website_Link
    Image{
      url
      alternativeText
    }
    imagetransforms
  }
}`

/* ========== Get Teams Categories ======== */
const GetTeamCategories = gql`
query GetTeamCategories{
  teamsCategories{
    id
    Title
    URL
    teams{
      id
      Title
      URL
    }
  }
}`

/* ========== Get Teams ======== */
const GetTeams = gql`
query GetTeams{
  teams(sort:"Sort:asc"){
    id
    Title
    URL
    Designation
    Sort
    image{
      url
      alternativeText
    }
    imagetransforms
    teams_categories{
      id
      Title
      URL
    }
  }
}`

/* ========== Get Teams Details ======== */
const GetTeamDetails = gql`
query GetTeamDetails($slug: String!){
  teams(where:{URL:$slug}){
    id
    Title
    URL
    Designation
    Sort
    Areas_of_Speciality
    Phone_Number
    Content
    Email
    image{
      url
      alternativeText
    }
    imagetransforms
    teams_categories{
      id
      Title
      URL
    }
  }
}`

/* ========== Featured Teams ======== */
const FeaturedTeams = gql`
query FeaturedTeams{
  teams(sort:"Sort:asc", limit:4){
    id
    Title
    URL
    Designation
    Sort
    image{
      url
      alternativeText
    }
    teams_categories{
      id
      Title
      URL
    }
  }
}`

/* ========== Get Careers ======== */
const GetCareers = gql`
query GetCareers{
  careers(sort:"createdAt:desc"){
    id
    Title
    Location
    Job_Positions
    URL
    Title_Image {
      alternativeText
      url
    }
  }
}`

/* ========== Get Careers Details ======== */
const CareersDetails = gql`
query CareersDetails($slug: String!){
  careers(where:{URL:$slug}){
    id
    Title
    Location
    URL
    Content
    Whats_In_It_For_You
  }
}`

/* ========== PopularSearch ======== */
const PopularSearch = gql`
query PopularSearch($slug: String!){  
  allMenus(where:{Primary_URL:$slug}){
    id
    Label
    Popular_Search_Component{
      Popular_Search_Module{
        popular_search{
          id
          Name
          Content
        }
      }
    }
  }
}`

/* ========== Area Guide by Name ======== */
const AreaGuidebyName = gql`
query AreaGuidebyName($name: String!){  
  areaGuides(where:{Name_contains:$name}){
    Name
    URL
    id
    Content
    Banner_Image{
      url
      alternativeText
    }
  }
}`


export const Queries = {
  FeaturedAreaGuides,
  GetSubMenus,
  GetAreaGuides,
  GetAreaGuideDetails,
  GetAreaGuidesLimit,
  GetNewsList,
  GetNewsCategories,
  GetNewsDetails,
  SimilarNews,
  GetTestimonials,
  GetAccreditations,
  GetTeamCategories,
  GetTeams,
  GetTeamDetails,
  FeaturedTeams,
  GetCareers,
  CareersDetails,
  PopularSearch,
  AreaGuidebyName
};

