import * as React from "react"
import { Link } from 'gatsby';

// Styles
import "./FooterTerms.scss"

const FooterTerms = ( { footerTermsMenu = [] } ) => {
    return (
        <div className="footer-terms-wrapper">
            {
                footerTermsMenu.map( ( footerTermsMenuItem, footerTermsMenuIndex ) => {
                    return (
                        <React.Fragment
                            key={ footerTermsMenuIndex }
                        >
                            <Link to={ '/'+(footerTermsMenuItem.all_menus[0].Secondary_URL ? footerTermsMenuItem.all_menus[0].Secondary_URL : footerTermsMenuItem.all_menus[0].Primary_URL)+'/' }>
                                { footerTermsMenuItem.all_menus[0].Label }
                            </Link>
                            {
                                ( footerTermsMenuIndex !== footerTermsMenu.length - 1 ) && (
                                    <span className="footer-terms-menu-divider">
                                        |
                                    </span>
                                )
                            }
                        </React.Fragment>
                    )
                } )
            }
        </div>
    )
}

export default FooterTerms
