import * as React from "react"
import { Col, Row } from "react-bootstrap"
import { Link } from 'gatsby';
import { menuLinks } from "../../../common/menuUtils";

// Styles
import "./FooterMenuListWeb.scss"

const FooterMenuListWeb = ( {footerMenuListData} ) => {
    return (
        <Row className="footer-menu-list-web-wrapper row-bordered">
            {
                footerMenuListData && footerMenuListData.map( ( footerMenuItem, footerMenuIndex ) => {
                    return (
                        <Col
                            key={ footerMenuIndex }
                            className="footer-menu-list"
                        >
                            <p className="footer-menu-list-title">{ footerMenuItem.Heading }</p>
                            <ul>
                                {
                                    footerMenuItem.Menu_Module.map( ( subMenuListItem, subMenuIndex ) => {
                                        return (
                                            <li key={ subMenuIndex }>
                                                <Link to={ subMenuListItem.Custom_Link ? subMenuListItem.Custom_Link : menuLinks(subMenuListItem.all_menu) }>{ subMenuListItem.Label }</Link>
                                            </li>
                                        )
                                    } )
                                }
                            </ul>
                        </Col>
                    )
                } )
            }
        </Row>
    )
}

export default FooterMenuListWeb
