import * as React from "react"

// Styles
import "./FooterCopyRights.scss"

const FooterCopyRights = ( { footerCopyRightsData } ) => {
    return (
        <div className="footer-copyrights-wrapper">
            <span className="footer-copyrights-content">
                Copyright &copy; Resi.Land {new Date().getFullYear()}. All Rights Reserved.
            </span>
            <br />
            <span className="footer-site-by">
                <span>Site by</span>
                <span className="footer-starberry-logo">
                    <a href="#">
                        <img
                            src={ require( `../../../../images/starberry-logo.svg` ).default }
                            alt="starberry logo"
                        />
                    </a>
                </span>
            </span>
        </div>
    )
}

export default FooterCopyRights
