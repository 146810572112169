import React, {useState} from "react"
import { Col, Row } from "react-bootstrap"

// Styles
import "./FooterSearch.scss"
import PopularSearchItem from "./PopularSearchItem";
import {PopularSearch} from "../../../../queries/common_use_query";

const FooterSearch = (props) => {

    const [search, setSearch] = useState(false);
    
    const openPopular = () => {
        setSearch(!search);
    };

    const { loading, error, data } = PopularSearch(props.slug);

    return (
        <Row className={`footer-search-wrapper row-bordered ${search ? "actv" : ""}`}>
            <Col>
                <p>
                    Popular Searches
                </p>
                <span>
                    <a href="javascript:;" onClick={openPopular} className="toggle_btn">
                        {search ? <img
                            src={ require( `../../../../images/minus-icon.png` ).default }
                            alt="minus icon"
                        /> : <img
                            src={ require( `../../../../images/plus-icon.png` ).default }
                            alt="plus icon"
                        />}
                    </a>
                </span>
            </Col>
            
            <PopularSearchItem data={data?.allMenus && data?.allMenus?.length > 0 ? data?.allMenus[0]?.Popular_Search_Component : ""} />
        </Row>
    )
}

export default FooterSearch
